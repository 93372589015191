.footer {
  width: 100%;
  padding-top: 20px;
  background-color: #e8e9ea;
}

.footerWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 20px;
}

.FooterTextFlex {
  display: flex;
  gap: 12px;
}

.svgFlexRow {
  display: flex;
  gap: 24px;
}
.callcenterText {
  font-size: 14px;
  color: #1b1e28;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.FooterTextFlex span {
  color: #1b1e28;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.FooterTextFlex p {
  color: #66686f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.companyContents {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.companyContents ul {
  display: flex;
  gap: 4px;
  align-items: center;
}
.companyContentsWrap {
  display: flex;
  gap: 4px;
  align-items: center;
}

.companyinfo {
  color: #1b1e28;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.infomationData {
  color: #66686f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.companyinfos {
  flex-basis: 10%;
}
