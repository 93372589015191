.headers {
  position: fixed;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 50;
  background-color: #fff;
}

@media (min-width: 500px) {
  .headers {
    width: 375px;
  }
}

.headersContents {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 15px;
  margin: 0 auto;
}

.headersContentsFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.headersContentsFlex span {
  cursor: pointer;
  font-size: 14px;
  flex-basis: 20%;
}

.headersContentsFlex p {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #1b1e28;
  flex-basis: 50%;
}

.headersContentsFlex div {
  flex-basis: 20%;
  display: flex;
  justify-content: end;
}

@media screen and (min-width: 1080px) and (max-width: 1200px) {
  .headers {
    width: 100%;
  }

  .headersContents {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .headersContentsFlex span {
    font-size: 24px;
  }

  .headersContentsFlex p {
    font-size: 32px;
  }

  /* 아이콘 크기 조정 */
  .headersContentsFlex img {
    width: 40px;
    height: 40px;
  }

  /* 좌우 여백 추가 */
  .headersContentsFlex span,
  .headersContentsFlex div {
    padding: 0 20px;
  }
}
