p {
  color: var(--Gray-Gray-200, #96989c);
  font-family: "Pretendard Variable";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

ul > li {
  color: var(--Gray-Gray-200, #96989c);
  font-family: "Pretendard Variable";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  margin-bottom: 1px;
}

.ContentsSection {
  width: 100%;
}

.imgBoxSection {
  width: 100%;
  background: linear-gradient(235deg, #fff 40.93%, #fffaec 83.39%);
}

.imgBoxSection img {
  width: 100%;
  object-fit: cover;
}

.reward {
  padding-top: 20px;
  padding-bottom: 10px;
}

.imageContentsWrap {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  gap: 12px;
  padding: 0 20px;
}

.imageContentsWrap img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.divingLine {
  width: 100%;
  height: 1px;
  background: var(--Gray-Gray-bg, #f3f4f6);
  margin: 24px 0 24px 0;
}

.textWrap {
  width: 100%;
  padding: 0px 20px 0px 20px;
  margin-bottom: 60px;
}

.textWrap article img {
  width: 100%;
  margin: 0 auto;
}

.listStyle li span {
  width: 10px;
}

.eventText li p,
span {
  color: #96989c;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
}

.listStyle li p,
span {
  color: #66686f;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
}

@media screen and (min-width: 1080px) and (max-width: 1200px) {
  p,
  ul > li {
    font-size: 28px; /* 폰트 크기를 18px에서 28px로 증가 */
    line-height: 160%;
  }

  .imageContentsWrap {
    margin-top: 48px;
    gap: 24px;
    padding: 0 40px;
  }

  .divingLine {
    margin: 48px 0 48px 0;
  }

  .textWrap {
    padding: 0px 40px 0px 40px;
    margin-bottom: 120px;
  }

  .eventText li p,
  .eventText li span,
  .listStyle li p,
  .listStyle li span {
    font-size: 28px; /* 폰트 크기를 18px에서 28px로 증가 */
    line-height: 39.2px; /* 라인 높이도 비례적으로 증가 */
  }

  /* 추가: 제목이나 다른 텍스트 요소의 폰트 크기도 조정 */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 36px; /* 제목의 폰트 크기를 더 크게 설정 */
  }

  /* 추가: 버튼 텍스트의 폰트 크기 조정 */
  button,
  .button {
    font-size: 32px; /* 버튼 텍스트의 폰트 크기를 크게 설정 */
  }

  .imagesWrap {
    width: 25rem;
    height: 25rem;
  }
}
