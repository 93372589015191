.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modalContent {
  background-color: white;
  width: 100%;
  padding: 40px 20px;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
  z-index: 3000;
  position: absolute;
  bottom: 0;
}

.modalContentWrap {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.modalContentWrap_Title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalContentWrap_Title h3 {
  color: #1b1e28;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin: 0;
}

.cancel {
  display: flex;
  justify-content: end;
}

.redirectBtnContents {
  width: 100%;
}

.redirectBtnContents ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.redirectBtnContents ul li {
  border: 1px solid #386937;
  width: 100%;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  border-radius: 12px;
}

.redirectBtnContents ul li p {
  text-align: center;
  width: 100%;
  color: #1b1e28;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
