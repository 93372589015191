.FinishWrap {
  display: inline-flex;
  width: 100%;
  height: 460px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}

.FinishIcon {
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.h3_Finish {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.p_Finish {
  font-size: 16px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
}

.FinishText {
  color: #a3baa3;
  text-align: center;
  font-family: "Pretendard Variable";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-top: 16px;
}

.bottomText {
  color: #386937;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
  cursor: pointer;
}

.button {
  padding: 18px 0px;
  border-radius: 12px;
  line-height: 20px;
  text-align: center;
}

.buttontext {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

/* Finish.module.css */
.button.loading {
  cursor: not-allowed;
  background-color: #ccc; /* 로딩 중 배경색 변경 */
  color: #666; /* 로딩 중 텍스트 색상 변경 */
  transition: background-color 0.3s ease, color 0.3s ease; /* 변경 효과 추가 */
}

.button.loading span {
  animation: moveText 0.5s infinite alternate; /* 텍스트 움직이는 애니메이션 추가 */
}

@keyframes moveText {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(5px);
  }
}

/* ModalFinish.module.css 파일에 아래 CSS 코드를 추가합니다. */
@keyframes loading {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.dotAnimation {
  display: inline-block;
  animation: loading 0.7s infinite alternate;
}

.dotAnimation:nth-child(2) {
  animation-delay: 0.1s;
}

.dotAnimation:nth-child(3) {
  animation-delay: 0.2s;
}

.dotAnimation:nth-child(4) {
  animation-delay: 0.3s;
}

.dotAnimation:nth-child(5) {
  animation-delay: 0.4s;
}

.dotAnimation:nth-child(6) {
  animation-delay: 0.5s;
}

.dotAnimation:nth-child(7) {
  animation-delay: 0.6s;
}
