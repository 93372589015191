/* tirpSelect_CSS*/

.TripSection {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.stickycontainer {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0 1.25rem;
  position: relative;
}
.stickyHeader {
  position: sticky;
  top: 3.125rem;
  background: white;
  z-index: 50;
}

/* 끝 */
.title {
  color: #1b1e28;
  padding: 0 1.25rem;
  line-height: 140%;
  font-weight: 600;
  font-size: 1.5rem;
  font-style: normal;
}

.tripSelectBox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 0rem 0.625rem 0;
}

.tripNation {
  color: #1b1e28;
  line-height: 1.25rem;
  font-weight: 500;
  font-size: 1rem;
}

.inputSearchboxWrap {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f3f4f6;
  width: 100%;
  border-radius: 0.75rem;
}

.inputSearchBox {
  padding: 1rem;
  background: none;
  width: 100%;
  margin-left: 1.75rem;
  color: #b8b9bc;
  font-size: 0.875rem;
  font-weight: normal;
}
.inputSearchBox:focus {
  outline: none;
}

.SearchIcon {
  position: absolute;
  left: 5%;
}

/* RecommandCSS 시작 */

.RecommandSection {
  width: 100%;
  background-color: #f3f4f6;
  padding: 1.5rem 1.25rem 1.25rem 1.25rem;
}

/* 오늘의 추천 여행지 */
.RecommandSection_title {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 1.5rem;
  color: #1b1e28;
}

.RecommandSectionContents {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  margin-top: 1.25rem;
}

.RecommandSectionContents_List {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  padding: 1rem 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #000000;
  background-image: linear-gradient(to bottom, #000000, #ffffff);
}

.followUp_NationWrap {
  background: rgba(0, 0, 0, 0.4);
  display: inline-block;
  border-radius: 0.5rem;
  padding: 0.25rem;
  width: 6.875rem;
}

.followUp_Nation {
  display: flex;
  gap: 0.125rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.followUp_Nation_text {
  color: #e8c746;
  font-weight: bold;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  font-style: normal;
}

.countryFlexRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
.countryImages {
  width: 1.75rem;
  height: 1.75rem;
  background-color: #fff;
  border-radius: 0.25rem;
  box-sizing: border-box;
}

.countryCommon {
  width: 16rem;
  color: #e8e9ea;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}

.recommandInfoWrap {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: start;
}

.recommandInfo {
  color: #e8e9ea;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}
.capital,
.population {
  color: #b8b9bc;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

/* 선택된 나라 */
.selectedCountryWrap {
  width: 100%;
  padding: 1rem 1.25rem 1.25rem 1.5rem;
}

.selectedContents {
  border: 0.0625rem solid #386937;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 0.75rem;
  display: flex;
}

.selectedContentsWrap {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.selectedImageWrap {
  width: 5.75rem;
  height: 5.75rem;
  display: flex;
  border-radius: 0.75rem;
  box-shadow: 0.00625rem 0.00625rem 0.125rem 0rem;
}
.selectedImage {
  width: 100%;
  border-radius: 0.75rem;
  object-fit: cover;
}

.selectedContentsInfo {
  display: flex;
  flex-direction: column;
}

.selectedTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.selectedCountry {
  font-size: 1rem;
  color: #1b1e28;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
}
.RecommendInfo {
  align-self: stretch;
  color: #66686f;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  margin-bottom: 0.25rem;
}
.selectedRecommendInfo {
  display: flex;
  flex-direction: column;
}
.selectedRecommendInfo p {
  color: #96989c;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

/* 버튼 css */

.buttonWrap {
  display: flex;
  flex-direction: column;
  background-color: #f3f4f6;
}

/* 데이터 바인딩 css */

.FlagDataFlex {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.375rem;
}

.NationNames {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #1b1e28;
}

.DataTextCol {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.DataTextCol p {
  color: #96989c;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: left;
}

.dataLabel {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #66686f;
}

.DataTextCol p {
  color: #96989c;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}

.ImageWrapper {
  width: 1.375rem;
  height: 1.375rem;
  display: flex;
  border-radius: 0.75rem;
}

.fetchImages {
  width: 100%;
  border-radius: 0.25rem;
  object-fit: cover;
  box-sizing: border-box;
  border: 0.0625rem solid #000;
}

.buttonWrap_bg {
  width: 100%;
  position: fixed;
  background-color: #f3f4f6;
  left: 50%;
  transform: translateX(-50%);
}

.buttonWrap_line {
  padding-top: 3.5rem;
  padding-bottom: 1.75rem;
  background-color: #f3f4f6;
  z-index: 19;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  filter: blur(2px);
}

.selectedData {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
}

.RecommandSectionContents_List {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  padding: 1rem 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #000000;
  position: relative;
  overflow: hidden;
}

.RecommandSectionContents_List::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(1px);
  z-index: 1;
}

.RecommandSectionContents_List::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.RecommandSectionContents_List > * {
  position: relative;
  z-index: 3;
}

@media screen and (min-width: 1080px) and (max-width: 1200px) {
  .TripSection {
    margin-bottom: calc(16px * 1.5);
  }

  .stickycontainer {
    padding: calc(10px * 1.5) calc(20px * 2) calc(10px * 1.5) calc(20px * 2);
  }

  .stickyHeader {
    top: calc(30px * 1.5);
  }

  .title {
    padding: 0 calc(20px * 2);
    font-size: calc(24px * 1.5);
  }

  .tripSelectBox {
    gap: calc(16px * 1.5);
    padding: calc(24px * 1.5) 0 calc(10px * 1.5) 0;
  }

  .tripNation {
    font-size: calc(16px * 1.5);
  }

  .inputSearchboxWrap {
    border-radius: calc(12px * 1.5);
  }

  .inputSearchboxWrap input::placeholder {
    font-size: calc(14px * 1.5);
    padding-left: 20px;
  }

  .inputSearchboxWrap img {
    width: 30px;
    height: 30px;
    left: 5%;
    top: 30%;
  }

  .inputSearchBox {
    padding: calc(16px * 1.5);
    margin-left: calc(28px * 1.5);
    font-size: calc(14px * 1.5);
  }

  .SearchIcon {
    left: calc(5% * 1.5);
  }

  .selectedContentsWrap {
    gap: calc(12px * 1.5);
  }

  .RecommandSection {
    padding: calc(24px * 1.5) calc(20px * 2) calc(60px * 1.5) calc(20px * 2);
  }

  .selectedData {
    gap: calc(6px * 1.5);
  }

  .RecommandSection_title {
    font-size: calc(16px * 1.5);
    margin-top: calc(24px * 1.5);
  }

  .RecommandSectionContents {
    gap: calc(16px * 1.5);
    margin-top: calc(20px * 1.5);
  }

  .RecommandSectionContents_List {
    border-radius: calc(12px * 1.5);
    padding: calc(16px * 1.5);
  }

  .followUp_NationWrap {
    border-radius: calc(8px * 1.5);
    padding: calc(4px * 1.5);
    width: calc(110px * 1.5);
  }

  .followUp_Nation_text {
    font-size: calc(12px * 1.5);
  }

  .countryFlexRow {
    gap: calc(8px * 1.5);
    margin-top: calc(16px * 1.5);
    margin-bottom: calc(24px * 1.5);
  }

  .countryCommon {
    width: calc(267px * 1.5);
    font-size: calc(24px * 1.5);
  }

  .recommandInfo {
    color: #e8e9ea;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .capital,
  .population {
    font-size: calc(14px * 1.5);
    line-height: 100%;
    margin-top: 10px;
  }

  .selectedCountryWrap {
    padding: calc(16px * 1.5) calc(20px * 2) calc(20px * 1.5) calc(20px * 2);
  }

  .selectedContents {
    border-radius: calc(8px * 1.5);
    padding: calc(12px * 1.5) calc(16px * 1.5) calc(12px * 1.5) calc(12px * 1.5);
    gap: calc(12px * 1.5);
  }

  .selectedImageWrap {
    width: calc(92px * 1.5);
    height: calc(92px * 1.5);
    border-radius: calc(12px * 1.5);
    box-shadow: 0.1px 0.1px calc(2px * 1.5) 0px;
  }

  .selectedTitle {
    gap: calc(8px * 1.5);
    margin-bottom: calc(8px * 1.5);
  }

  .selectedTitle p {
    font-size: 1.5rem;
    color: #1b1e28;
  }

  .selectedCountry {
    font-size: calc(16px * 1.5);
  }

  .RecommendInfo {
    font-size: calc(14px * 1.5);
    margin-bottom: calc(4px * 1.5);
  }

  .selectedRecommendInfo p {
    font-size: calc(12px * 1.5);
  }

  .buttonWrap_bg {
    height: calc(20px * 1.5);
    bottom: calc(60px * 1.5);
  }

  .buttonWrap_line {
    padding-top: calc(46px * 1.5);
    padding-bottom: calc(28px * 1.5);
  }

  .FlagDataFlex {
    gap: calc(8px * 1.5);
    margin-bottom: calc(6px * 1.5);
  }

  .NationNames {
    font-size: calc(16px * 1.5);
  }

  .DataTextCol {
    gap: calc(4px * 1.5);
  }

  .dataLabel {
    font-size: calc(14px * 1.5);
  }

  .ImageWrapper {
    width: calc(22px * 1.5);
    height: calc(22px * 1.5);
    border-radius: calc(12px * 1.5);
  }

  .fetchImages {
    border-radius: calc(4px * 1.5);
  }
}

@media screen and (min-width: 1280px) {
  .buttonWrap {
    max-width: 375px;
  }
  .buttonWrap_bg {
    max-width: 375px;
  }

  .buttonWrap_line {
    max-width: 375px;
  }
}
