.indemnityContents {
  width: 100%;
  padding: 0 20px;
}
.indemnityWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.title {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.subTitle {
  display: flex;
}
.title p {
  color: #1b1e28;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.subTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #1b1e28;
}
.subTitle p {
  color: #386937;
}
.wrap {
  border-radius: 12px;
  background-color: #ebf0eb;
  padding: 20px 16px;
  align-self: stretch;
  position: relative;
}
.ContentsFlexRow {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 8px;
}
.ContentsImgWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 12px;
}
.ContentsImg {
  width: 100%;
  object-fit: cover;
}
.platformInfoWrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.platformName {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #1b1e28;
}
.platformPrice {
  color: #1b1e28;
  font-weight: 700;
  line-height: 24px;
  font-size: 20px;
}
.platformDetailWrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.platformDetailWrap p {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.announce {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.8);
  width: 218px;
  margin-top: 12px;
  position: relative;
}
.announceText {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #386937;
  cursor: pointer;
}
.recommandModalBtn {
  position: absolute;
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  top: 0px;
  right: 0px;
  border-radius: 14px;
  background-color: #386937;
  cursor: pointer;
  z-index: 1;
}
.extraMarginBottom {
  margin-bottom: 1px;
}
.recommandModaTitle {
  color: #f0d983;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;
}
.premiumText {
  color: #386937;
  font-weight: 600;
}
.detailText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #66686f;
}
.selected {
  border: 1px solid #386937;
}
.flexCol {
  display: flex;
  flex-direction: column;
}

/* 키오스크 화면에서 보일 ui(세로가 긴 ui) */
@media screen and (min-width: 1079px) and (max-width: 1279px) {
  .indemnityContents {
    padding: 60px calc(20px * 1.5);
  }
  .indemnityWrap {
    gap: calc(20px * 1.5);
  }
  .title {
    margin-bottom: calc(20px * 1.5);
  }
  .title p,
  .subTitle {
    font-size: calc(24px * 1.5);
  }
  .wrap {
    border-radius: calc(12px * 1.5);
    padding: calc(20px * 1.5) calc(16px * 1.5);
  }
  .ContentsFlexRow {
    margin-bottom: calc(20px * 1.5);
    gap: calc(8px * 1.5);
  }

  .ContentsImgWrap {
    width: calc(48px * 1.5);
    height: calc(48px * 1.5);
    border-radius: calc(12px * 1.5);
  }
  .platformName {
    font-size: calc(16px * 1.5);
    line-height: calc(20px * 1.5);
  }
  .platformPrice {
    line-height: calc(24px * 1.5);
    font-size: calc(20px * 1.5);
  }
  .platformDetailWrap {
    gap: calc(4px * 1.5);
  }
  .platformDetailWrap p {
    font-size: calc(14px * 1.5);
    line-height: calc(20px * 1.5);
  }
  .announce {
    padding: calc(6px * 1.5) calc(12px * 1.5);
    border-radius: calc(14px * 1.5);
    width: calc(218px * 1.5);
    margin-top: calc(12px * 1.5);
  }
  .announceText {
    font-size: calc(12px * 1.5);
    line-height: calc(16px * 1.5);
  }
  .recommandModalBtn {
    padding: calc(4px * 1.5) calc(10px * 1.5);
    gap: calc(4px * 1.5);
    border-radius: calc(14px * 1.5);
    top: -10px;
  }
  .recommandModaTitle {
    font-size: calc(10px * 1.5);
    line-height: calc(16px * 1.5);
  }
  .detailText {
    font-size: calc(14px * 1.5);
    line-height: calc(20px * 1.5);
  }
}
