* {
  margin: 0;
  padding: 0;
}

body {
  padding: 0 !important;
}
/* 스크롤 너비 제어*/

@media (max-width: 700px) {
  .MuiPaper-root.MuiDialog-paper {
    max-width: 375px;
  }
}

.slick-list {
  width: 100%;
  overflow: visible !important;
}

.claimMainSlick_sliderContainer__Ii2Vg {
  height: 0 !important;
}

.slick-track {
  width: 5000px !important;
  display: flex !important;
}

.slick-slide {
  margin-right: 10px; /* 슬라이드 간격 설정 */
}

.MuiTouchRipple-root {
  width: 30px;
  height: 30px;
}

.MuiGrid-item button {
  width: 37px;
  height: 34px;
}
.css-ypiqx9-MuiDialogContent-root {
  padding: 20px !important;
}
