.contentsHead {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 50;
}

.commonModals {
  background-color: white;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 16px;
}

@media screen and (min-width: 1280px) {
  .commonModals {
    max-width: 375px;
  }
}

.contentsWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.title {
  color: #353535;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  background-color: #fff;
  z-index: 10;
  padding: 16px;
}

.inputContents {
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
  padding: 0.75rem 0;
  cursor: pointer;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f1f5f9;
}

.active {
  border: 1px solid #386937;
}

.inputContents:focus {
  outline: none;
}

.input {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.375rem 2.5rem;
  outline: none;
  background-color: #f3f4f6;
  width: 100%;
}

.flexEnd {
  display: flex;
  justify-content: end;
  margin-right: 0.5rem;
}

.flex {
  display: flex;
}

.searchImages {
  position: absolute;
  cursor: pointer;
  top: 28%;
  right: 90%;
}

.nationListContents {
  border-radius: 0.5rem;
  max-height: 250px;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE, Edge */
}

.nationListContents::-webkit-scrollbar {
  display: none;
}

.nationListContents ul li {
  padding: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 0.25rem;
  color: #1b1e28;
  font-weight: 500;
  cursor: pointer;
  font-style: normal;
}

@media screen and (min-width: 1079px) and (max-width: 1279px) {
  .commonModals {
    border-top-left-radius: calc(0.5rem * 1.5);
    border-top-right-radius: calc(0.5rem * 1.5);
    width: 100%;
    height: 1000px;
    padding: 2rem 2.5rem;
  }

  .contentsWrap {
    margin-bottom: 1.5rem;
  }

  .title {
    font-size: calc(20px * 1.5);
  }

  .inputContents {
    border-radius: 0.5rem;
    margin-bottom: calc(0.75rem * 3);
    padding: calc(0.75rem * 1.5) 2rem;
  }

  .input {
    font-size: calc(0.875rem * 1.5);
  }
  .input::placeholder {
    font-size: calc(0.875rem * 1.5);
  }
  .cancel {
    width: 40px;
    height: 40px;
  }

  .searchImages {
    position: absolute;
    cursor: pointer;
    width: 30px;
    height: 30px;
    left: 3%;
    top: 30%;
  }

  .nationListContents {
    border-radius: 0.5rem;
    max-height: 1000px;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE, Edge */
  }

  .nationListContents::-webkit-scrollbar {
    display: none;
  }

  .nationListContents ul li {
    padding: 1.5rem;
    font-size: calc(1rem * 1.5);
    line-height: 1.25rem;
    margin-bottom: calc(0.9rem * 1.5);
    color: #1b1e28;
    font-weight: 400;
    cursor: pointer;
    font-style: normal;
  }
}
