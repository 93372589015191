.titltBox {
  max-width: 100%;
  padding: 0 20px;
}
.titltWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-center; /* Changed from center to flex-start */
  gap: 2.25rem;
  margin-top: 0.625rem;
}
.title {
  color: #1b1e28;
  width: 100%;
  line-height: 2.1rem;
  font-weight: 600;
  font-size: 1.5rem;
}

.tripSelect {
  display: flex;
  flex-direction: column;
}

.calendars {
  color: #1b1e28;
  font-weight: 500;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  width: 20%;
}

.inputWrap {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.calendarBox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.InputFlex {
  display: flex;
  gap: 0.5625rem;
  width: 100%;
  margin: 0 auto;
}
.userInpoInputFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5625rem;
}

.InputContent {
  padding: 1rem 1rem;
  border-radius: 0.75rem;
  background-color: #f3f4f6;
  outline: none;
  width: 100%;
  font-size: 0.875rem;
  color: #1b1e28;
  line-height: 1.5rem;
}
.InputContent::placeholder {
  color: #b8b9bc;
}

.genderContentsBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.UserInfoBox {
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.userInfo {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  color: #1b1e28;
}
.companionsTotal {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  color: #386937;
}

.genderInput {
  position: relative;
  width: 100%;
}

.faCheckStyle {
  position: absolute;
  right: 1rem;
  color: #96989c;
  top: 0.875rem;
  bottom: 0;
  cursor: pointer;
}

.GenderOption {
  position: absolute;
  left: 0.0625rem;
  padding: 0.75rem 0;
  z-index: 100;
  background-color: #f3f4f6;
  width: 100%;
  top: 3.125rem;
  border-radius: 0 0 0.75rem 0.75rem;
  border-top: 0.0625rem solid #e8e9ea;
  transition: 2s;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.genderBtn {
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  color: #1b1e28;
}

.companionCheckicon {
  position: absolute;
  right: 1rem;
  top: 0.875rem;
  bottom: 0;
  cursor: pointer;
}

.companionIndex_DeleteBtn {
  display: flex;
  width: 100%;
  margin-top: 1.25rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  cursor: pointer;
}

.companionIndex {
  color: #66686f;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}
.DeleteBtn {
  display: flex;
  padding: 0.25rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.75rem;
  background-color: #e8e9ea;
  font-size: 0.75rem;
}

.AddcopanionButtonWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  background-color: #ebf0eb;
  padding: 0.625rem 1rem;
  width: 46%;
  margin: 0 auto;
  border-radius: 0.75rem;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

.AddcopanionButton_Text {
  color: #386937;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.calendarTtitle {
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  color: #1b1e28;
}
.input::placeholder {
  color: #b8b9bc;
}

@media screen and (min-width: 67.5rem) and (max-width: 75rem) {
  .titltWrap {
    gap: calc(2.25rem * 1.5);
    margin-top: calc(0.625rem * 1.5);
    padding: 0 calc(20px * 1.5);
  }
  .title {
    line-height: calc(2.1rem * 1.5);
    font-size: calc(1.5rem * 1.5);
    margin-bottom: 2.25rem;
  }
  .calendars {
    font-size: calc(0.875rem * 1.5);
    line-height: calc(1.25rem * 1.5);
  }
  .inputWrap {
    gap: calc(0.5rem * 1.5);
  }
  .calendarBox {
    gap: calc(1rem * 1.5);
  }
  .InputFlex,
  .userInpoInputFlex {
    gap: calc(0.5625rem * 1.5);
  }
  .InputContent {
    border-radius: calc(0.75rem * 1.5);
    font-size: calc(0.875rem * 1.5);
    line-height: calc(1.5rem * 1.5);
    padding: 2rem 1rem; /* Updated y-padding to 2rem */
  }
  .InputContent::placeholder {
    font-size: calc(0.875rem * 1.5);
  }
  .genderContentsBox {
    gap: calc(1rem * 1.5);
  }
  .UserInfoBox {
    gap: calc(1rem * 1.5);
    margin-top: calc(1.5rem * 1.5);
  }
  .userInfo,
  .companionsTotal {
    font-size: calc(0.875rem * 1.5);
  }
  .faCheckStyle,
  .companionCheckicon {
    right: calc(1rem * 1.5);
    top: 2rem; /* Updated to match new padding */
  }
  .GenderOption {
    padding: calc(0.85rem * 1.5) 0;
    top: calc(3.125rem * 1.5);
    border-radius: 0 0 calc(0.75rem * 1.5) calc(0.75rem * 1.5);
    border-top: calc(0.0625rem * 1.5) solid #e8e9ea;
  }
  .genderBtn {
    padding: calc(0.75rem * 1.5) calc(1rem * 1.5);
    font-size: calc(0.875rem * 1.5);
  }
  .companionIndex_DeleteBtn {
    margin-top: calc(1.25rem * 1.5);
    margin-bottom: calc(0.75rem * 1.5);
  }
  .companionIndex {
    font-size: calc(0.875rem * 1.5);
    line-height: calc(1.25rem * 1.5);
  }
  .DeleteBtn {
    padding: calc(0.25rem * 1.5) calc(0.75rem * 1.5);
    gap: calc(0.625rem * 1.5);
    border-radius: calc(0.75rem * 1.5);
    font-size: calc(0.75rem * 1.5);
  }
  .AddcopanionButtonWrap {
    gap: calc(0.375rem * 1.5);
    padding: calc(0.625rem * 1.5) calc(1rem * 1.5);
    border-radius: calc(0.75rem * 1.5);
    margin-bottom: calc(1.25rem * 1.5);
    margin-top: calc(1.25rem * 1.5);
  }
  .AddcopanionButton_Text {
    font-size: calc(0.875rem * 1.5);
    line-height: calc(1.5rem * 1.5);
  }
  .calendarTtitle {
    font-size: calc(1.375rem * 2);
    line-height: calc(1.5rem * 1.5);
  }
  .input::placeholder {
    font-size: calc(0.875rem * 1.5);
  }
}
