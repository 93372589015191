.buttonWrap {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  padding: 18px 20px;
  margin-top: 50px;
}

.buttonBox {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 50;
}

.buttonStyle {
  background-color: #386937;
  color: white;
  font-weight: bold;
  width: 335px;
  padding-top: 1rem; /* 4/4 = 1rem (assuming base font size of 1rem) */
  padding-bottom: 1rem; /* 4/4 = 1rem (assuming base font size of 1rem) */
  border-radius: 12px; /* Assuming base font size of 1rem */
}

@media screen and (min-width: 1080px) and (max-width: 1200px) {
  .buttonBox {
    width: 32rem;
    padding: 0 20px;
  }
  .buttonStyle {
    font-size: 24px;
    padding: 40px 0;
    width: 100%;
    border-radius: 20px;
  }
  .buttonWrap {
    margin-top: 120px;
  }
  .buttonWrap button {
    font-size: 2rem;
  }
}

/*finish btnBox*/

.finishButtonBOX {
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 50;
}

/* 모달버튼 */

.ModalbuttonBox {
  width: 100%;
  text-align: center;
  padding-top: 20px;
}

@media screen and (min-width: 500px) {
  .ModalbuttonBox {
    width: 360px;
  }
}

.ModalbuttonBtnStyle {
  background-color: #386937;
  color: white;
  font-weight: bold;
  width: 100%;
  margin: 0 auto;
  padding-top: 1rem; /* 4/4 = 1rem (assuming base font size of 1rem) */
  padding-bottom: 1rem; /* 4/4 = 1rem (assuming base font size of 1rem) */
  border-radius: 12px; /* Assuming base font size of 1rem */
}
