.animateDiv_1 {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
}

.animateDiv_2 {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 1.5rem 1.25rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.3);
  height: auto;
  overflow-y: auto; /* Changed from hidden to auto */
  border-radius: 0.5rem 0.5rem 0 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.animateDiv_2::-webkit-scrollbar {
  display: none;
}

.animationContent {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.animationContent h2 {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #1b2e28;
  flex-basis: 90%;
}

.cursor {
  cursor: pointer;
}

.highlightTextContents {
  margin-top: 2.5rem;
  border: 0.125rem solid #f1f3f4;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  padding: 1.25rem 1rem;
  text-align: left;
  height: 15rem;
  overflow-y: auto; /* Changed from scroll to auto */
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 5rem; /* Added space for the button */
}

.highlightTextContents::-webkit-scrollbar {
  display: none;
}

.highlightTextContents span {
  color: #66686f;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.8;
}

/* New styles for the button container */
.buttonContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 -0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 31.25rem) {
  .animateDiv_2 {
    width: 90%;
    max-width: 40rem;
  }
}

@media screen and (min-width: 67.5rem) and (max-width: 75rem) {
  .animateDiv_2 {
    width: 95%;
    max-width: 65rem;
  }

  .animationContent h2 {
    font-size: 2rem;
  }

  .highlightTextContents {
    height: 45rem;
  }
  .highlightTextContents span {
    font-size: calc(0.875rem * 2);
  }
  .cursor {
    width: 40px;
    height: 40px;
  }
  .cursor img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
