/* errorModal.module.css */

.errorModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  padding: 0 1.25rem;
  width: 100%;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 1001;
  animation: fadeIn 0.3s ease-in-out;
}

.modalContentWrap {
  border-radius: 0.75rem;
  background: #fff;
  width: 94%;
  margin: 0 auto;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
}

.buttonWrap {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 0.625rem;
  margin-top: 1.25rem;
}

.confirmButton {
  background-color: #386537;
  color: white;
  width: 100%;
  padding: 1rem 0;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: center;
}

.cancelButton {
  background-color: white;
  color: #386537;
  width: 100%;
  padding: 1rem 0;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: center;
  border: solid;
  border-width: thin;
}

.modalContentWrap h3 {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  width: 100%;
  color: #1b1e28;
  white-space: pre-line;
}

.modalContentWrap p {
  color: #1b1e28;
  font-size: 0.75rem;
  letter-spacing: -0.03125rem;
  text-align: center;
  width: 100%;
  font-weight: 600;
}

.commonX {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 1079px) and (max-width: 1279px) {
  .modalContent {
    padding: 0 1rem;
  }

  .modalContentWrap {
    padding: 3rem 2rem;
  }

  .confirmButton,
  .cancelButton {
    width: 50%;
    padding: 2rem 0;
    font-size: 2rem;
  }

  .modalContentWrap h3 {
    font-size: 2rem;
  }

  .modalContentWrap p {
    font-size: 0.6875rem;
  }
  .commonX {
    width: 100%;
  }
  .commonX img {
    width: 50px;
    height: 50px;
  }
  .modalContentWraps {
    border-radius: 0.75rem;
    background: #fff;
    width: 94%;
    margin: 0 auto;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }
}
